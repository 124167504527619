/** @jsx jsx */
import { forwardRef } from "react"

import { jsx } from "@trueskin-web/theme"

import Checkbox from "./checkbox"
import FormControl from "./form-control"
import FormErrorMessage from "./form-error-message"

const CheckboxControl = forwardRef(
  (
    {
      input,
      meta,
      className,
      mobileErrorMessagePosition,
      desktopErrorMessagePosition,
      dataTestId,
      ...props
    },
    ref
  ) => (
    <FormControl
      data-testid={dataTestId}
      isInvalid={meta.touched && meta.invalid}
      className={className}
      sx={{ display: "grid" }}
    >
      <Checkbox {...input} {...props} ref={ref} />
      <FormErrorMessage
        sx={{
          gridRow:
            mobileErrorMessagePosition === "top"
              ? ["1/2", "inherit"]
              : desktopErrorMessagePosition === "top"
              ? ["inherit", "1/2"]
              : "inherit",
        }}
      >
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  )
)

export default CheckboxControl
