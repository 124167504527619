/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const mastercardIcon  = {
  path: (
    <Fragment>
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6316 28.049C26.6582 29.7125 24.0984 30.7166 21.3011 30.7166C15.0597 30.7166 10 25.7171 10 19.55C10 13.3828 15.0597 8.38329 21.3011 8.38329C24.0984 8.38329 26.6582 9.38745 28.6316 11.0509C30.605 9.38745 33.1649 8.38329 35.9621 8.38329C42.2036 8.38329 47.2632 13.3828 47.2632 19.55C47.2632 25.7171 42.2036 30.7166 35.9621 30.7166C33.1649 30.7166 30.605 29.7125 28.6316 28.049Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6316 28.049C31.0615 26.0009 32.6023 22.9532 32.6023 19.55C32.6023 16.1467 31.0615 13.099 28.6316 11.0509C30.605 9.38745 33.1649 8.38329 35.9621 8.38329C42.2035 8.38329 47.2632 13.3828 47.2632 19.55C47.2632 25.7171 42.2035 30.7166 35.9621 30.7166C33.1649 30.7166 30.605 29.7125 28.6316 28.049Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6316 28.049C31.0615 26.0009 32.6023 22.9532 32.6023 19.55C32.6023 16.1467 31.0615 13.0991 28.6316 11.0509C26.2018 13.0991 24.661 16.1467 24.661 19.55C24.661 22.9532 26.2018 26.0009 28.6316 28.049Z"
        fill="#FF5E00"
      />
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" stroke="#F2F4F7" />
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 58 40",
}