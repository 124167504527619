/** @jsx jsx */
import { jsx } from "theme-ui"

export const starEmptyIcon  = {
  path: (
    <path
      d="M5.823 5.583l-.112.346H1.538L4.62 8.167l.294.214-.112.345-1.177 3.622 3.081-2.238L7 9.896l.294.214 3.081 2.239-1.177-3.623-.112-.345.294-.214L12.46 5.93H8.29l-.112-.346L7 1.961 5.823 5.583z"
      stroke="currentColor"
      fill="none"
    />
  ),
  viewBox: "-1 -0.8 16 16",
}