/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const FieldMessage = (props) => (
  <div
    sx={{
      whiteSpace: "break-spaces",
      flexDirection: "column",
      justifyContent: "flex-end",
      mt: 1,
      fontSize: 0,
    }}
    {...props}
  />
)

export default FieldMessage
