/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const paypalIcon  = {
  path: (
    <Fragment>
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.357 30.7471L24.7249 28.3319L23.9054 28.3122H19.9922L22.7117 10.4895C22.7202 10.4355 22.7476 10.3854 22.7876 10.3498C22.8278 10.3141 22.879 10.2946 22.9326 10.2946H29.5309C31.7216 10.2946 33.2333 10.7656 34.0225 11.6955C34.3925 12.1318 34.6282 12.5878 34.7423 13.0894C34.8619 13.6158 34.8638 14.2447 34.7472 15.0119L34.7387 15.0677V15.5593L35.1087 15.776C35.4203 15.9469 35.6679 16.1424 35.8579 16.3662C36.1743 16.7393 36.3791 17.2134 36.4656 17.7753C36.555 18.3533 36.5255 19.0413 36.3791 19.8201C36.2102 20.7157 35.9373 21.496 35.5686 22.1342C35.2297 22.7224 34.7978 23.2103 34.2849 23.5883C33.7953 23.9476 33.2136 24.2203 32.5559 24.3948C31.9185 24.5663 31.1918 24.6529 30.3947 24.6529H29.8813C29.5142 24.6529 29.1575 24.7895 28.8775 25.0345C28.5968 25.2846 28.4111 25.6263 28.3542 26L28.3154 26.2176L27.6653 30.4746L27.636 30.6308C27.6281 30.6803 27.6147 30.705 27.595 30.7216C27.5775 30.7369 27.5522 30.7471 27.5276 30.7471H24.357Z"
        fill="#28356A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4594 15.1245C35.4399 15.2546 35.4172 15.3876 35.392 15.5242C34.5219 20.142 31.5448 21.7372 27.7427 21.7372H25.8068C25.3418 21.7372 24.9498 22.0861 24.8775 22.5602L23.6056 30.899C23.5585 31.2104 23.7907 31.4908 24.0945 31.4908H27.5281C27.9346 31.4908 28.28 31.1855 28.3441 30.7711L28.3778 30.5908L29.0243 26.3505L29.0658 26.1179C29.1291 25.702 29.4753 25.3965 29.8818 25.3965H30.3953C33.7219 25.3965 36.3262 24.0007 37.0874 19.9609C37.4052 18.2734 37.2407 16.8643 36.3993 15.8733C36.1447 15.5745 35.8289 15.3265 35.4594 15.1245Z"
        fill="#298FC2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5488 14.7494C34.4157 14.7093 34.2786 14.673 34.1379 14.6402C33.9964 14.6082 33.8516 14.5799 33.7025 14.5551C33.1805 14.4679 32.6085 14.4266 31.9959 14.4266H26.8242C26.6967 14.4266 26.5757 14.4563 26.4675 14.5101C26.2289 14.6286 26.0518 14.862 26.0088 15.1477L24.9085 22.3503L24.877 22.5603C24.9493 22.0861 25.3412 21.7373 25.8063 21.7373H27.7422C31.5443 21.7373 34.5214 20.1412 35.3915 15.5243C35.4175 15.3876 35.4394 15.2547 35.4589 15.1245C35.2388 15.0037 35.0004 14.9005 34.7436 14.8125C34.6802 14.7907 34.6148 14.7697 34.5488 14.7494Z"
        fill="#22284F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0091 15.1478C26.0521 14.862 26.2292 14.6286 26.4678 14.5109C26.5768 14.4569 26.697 14.4272 26.8245 14.4272H31.9962C32.6088 14.4272 33.1808 14.4688 33.7028 14.5559C33.8519 14.5806 33.9967 14.6091 34.1382 14.6411C34.2789 14.6736 34.4161 14.7101 34.5491 14.75C34.6151 14.7704 34.6805 14.7916 34.7445 14.8125C35.0013 14.9005 35.2399 15.0046 35.46 15.1245C35.7189 13.4181 35.4578 12.2562 34.5652 11.2041C33.581 10.0457 31.8049 9.55002 29.532 9.55002H22.9335C22.4693 9.55002 22.0732 9.89888 22.0015 10.3738L19.2532 28.3798C19.199 28.736 19.4648 29.0574 19.8124 29.0574H23.886L26.0091 15.1478Z"
        fill="#28356A"
      />
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" stroke="#F2F4F7" />
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 58 40",
}