/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const amexIcon  = {
  path: (
    <Fragment>
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" fill="#1F72CD" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.159 14.1667L4.85718 26.2446H11.2042L11.9911 24.3188H13.7897L14.5765 26.2446H21.5628V24.7748L22.1854 26.2446H25.7992L26.4218 24.7437V26.2446H40.9514L42.7182 24.3689L44.3725 26.2446L51.8352 26.2601L46.5166 20.2393L51.8352 14.1667H44.4882L42.7684 16.0077L41.1662 14.1667H25.3598L24.0025 17.2841L22.6134 14.1667H16.2796V15.5864L15.575 14.1667H10.159ZM11.3871 15.8817H14.481L17.9977 24.0718V15.8817H21.3869L24.1032 21.754L26.6065 15.8817H29.9788V24.5484H27.9268L27.9101 17.7573L24.9185 24.5484H23.083L20.0747 17.7573V24.5484H15.8534L15.0531 22.6055H10.7295L9.93085 24.5467H7.66912L11.3871 15.8817ZM40.1997 15.8817H31.8561V24.5433H40.0705L42.7182 21.6727L45.2701 24.5433H47.9378L44.0604 20.2376L47.9378 15.8817H45.3858L42.7516 18.7194L40.1997 15.8817ZM12.8922 17.3481L11.4677 20.8093H14.315L12.8922 17.3481ZM33.9165 19.2583V17.6762V17.6747H39.1227L41.3944 20.2048L39.022 22.7489H33.9165V21.0216H38.4683V19.2583H33.9165Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" stroke="#F2F4F7" />
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 58 40",
}