/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"

import { Header as HeaderContainer } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const Header = ({ title, isFloating, disableNav, disableMobileHeader }) => {
  const {
    strapi: {
      header: { primaryCta, links },
    },
  } = useStaticQuery(graphql`
    {
      strapi {
        header {
          primaryCta {
            label
            targetUrl
          }
          links {
            label
            targetUrl
          }
        }
      }
    }
  `)

  return (
    <HeaderContainer
      primaryCta={primaryCta}
      links={links}
      title={title}
      isFloating={isFloating}
      disableNav={disableNav}
      disableMobileHeader={disableMobileHeader}
    />
  )
}

export default Header
