/** @jsx jsx */
import { Dialog as ReachDialog } from "@reach/dialog"
import "@reach/dialog/styles.css"

import { jsx } from "@trueskin-web/theme"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"

import "../styles/reach-dialog.css"

import Heading from "./heading"
import IconButton from "./icon-button"

const Dialog = ({
  title,
  children,
  onDismiss,
  noBackdropDismiss,
  noMobileFullscreen,
  ...props
}) => (
  <ReachDialog
    sx={{
      position: "relative",
      height: noMobileFullscreen ? "auto" : undefined,
      my: noMobileFullscreen ? "5vh" : undefined,
      py: [5, 6],
      px: [4, 5],
    }}
    onDismiss={noBackdropDismiss ? undefined : onDismiss}
    {...props}
  >
    {onDismiss && (
      <div sx={{ position: "absolute", top: 0, right: 0 }}>
        <IconButton
          data-testid="web-portal-close-dialog-btn"
          icon={closeIcon}
          onClick={onDismiss}
          sx={{
            p: [1, 2],
            zIndex: 1,
          }}
        />
      </div>
    )}
    {title && (
      <Heading
        sx={{
          mb: 3,
        }}
      >
        {title}
      </Heading>
    )}
    {children}
  </ReachDialog>
)

export default Dialog
