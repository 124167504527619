/** @jsx jsx */
import { useLocation } from "@reach/router"

import { jsx } from "@trueskin-web/theme"

import Button from "./button"
import Link from "./link"

const NavLink = ({
  doNotTrack,
  href,
  target = "_self",
  className,
  ...props
}) => {
  const location = useLocation()

  return (
    <Button
      variant="link"
      size="xsm"
      as={doNotTrack ? undefined : Link}
      href={doNotTrack ? undefined : href}
      target={target}
      onClick={
        doNotTrack
          ? () => {
              Object.assign(document.createElement("a"), {
                target,
                href,
              }).click()
            }
          : undefined
      }
      className={location.pathname === href ? `active ${className}` : className}
      sx={{ textDecoration: "none" }}
      {...props}
    />
  )
}

export default NavLink
