/** @jsx jsx */
import { Fragment, useState } from "react"

import { jsx } from "@trueskin-web/theme"
import { googleIcon } from "@trueskin-web/theme/icons/googleIcon"
import { starEmptyIcon } from "@trueskin-web/theme/icons/starEmptyIcon"
import { starHalfIcon } from "@trueskin-web/theme/icons/starHalfIcon"
import { starIcon } from "@trueskin-web/theme/icons/starIcon"

import HStack from "./h-stack"
import Icon from "./icon"

const getPercent = (rating, position) =>
  Math.round(Math.min(Math.max(rating - position, 0), 1) * 100)

export const RatingStar = ({ percent, ...otherProps }) => {
  let icon = starEmptyIcon

  if (percent >= 25 && percent < 75) {
    icon = starHalfIcon
  }

  if (percent > 50) {
    icon = starIcon
  }

  return <Icon icon={icon} {...otherProps} />
}

export const TrustpilotRating = ({ rating, compact = false, ...props }) => (
  <div
    sx={{
      display: "inline-grid",
      gridTemplateColumns: compact
        ? "auto"
        : ["repeat(2, auto)", "repeat(3, auto)"],
      gridGap: 2,
      alignItems: "center",
      justifyItems: "center",
      fontSize: 12,
      fontWeight: "bold",
    }}
    {...props}
  >
    <span
      sx={{
        display: compact ? null : ["none", "inline-grid"],
      }}
    >
      Hervorragend
    </span>
    <Rating
      rating={rating}
      size="sm"
      sx={{
        color: "white",
        svg: {
          backgroundColor: "trustpilotGreen",
          "&:not(:last-of-type)": {
            mr: "2px",
          },
        },
      }}
    />
    <div
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <RatingStar
        percent="100"
        color="trustpilotGreen"
        sx={{
          width: "20px",
          height: "20px",
        }}
      />
      <span
        sx={{
          mt: "1px",
          ml: "1px",
        }}
      >
        Trustpilot
      </span>
    </div>
  </div>
)

export const GoogleRating = ({ rating, compact = false, ...props }) => (
  <div
    sx={{
      display: "inline-grid",
      gridTemplateColumns: compact ? "auto" : "repeat(2, auto)",
      gridGap: 2,
      alignItems: "center",
      justifyItems: "center",
      fontSize: 12,
      fontWeight: "bold",
    }}
    {...props}
  >
    <Rating
      rating={rating}
      size="sm"
      sx={{
        color: "goldDark",
        backgroundColor: "white",
        py: 1,
        px: 2,
        borderRadius: 99,
      }}
    />
    <div
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon icon={googleIcon} size="sm" />
      <span
        sx={{
          ml: 1,
        }}
      >
        Google
      </span>
    </div>
  </div>
)

const ratingArr = [...Array(5)]

const Rating = ({
  text = null,
  rating = 0,
  size,
  onClick = (score) => {},
  ...props
}) => {
  const [rate, setRate] = useState(0)

  return (
    <Fragment>
      {text && (
        <Fragment>
          <span
            sx={{
              color: "primary",
            }}
          >
            {text}
          </span>
          <br />
        </Fragment>
      )}
      <HStack
        spacing="0"
        sx={{
          display: "inline-flex",
          whiteSpace: "nowrap",
        }}
        {...props}
      >
        {ratingArr.map((_, position) => (
          <RatingStar
            key={position}
            percent={
              rating ? getPercent(rating, position) : getPercent(rate, position)
            }
            size={size}
            onClick={() => onClick(position + 1)}
            onMouseEnter={() => setRate(position + 1)}
            onMouseLeave={() => setRate(0)}
            sx={{
              cursor: !rating && "pointer",
            }}
          />
        ))}
      </HStack>
    </Fragment>
  )
}
export default Rating
