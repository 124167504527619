/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const arrowLongRightIcon  = {
  path: (
    <Fragment>
      <path d="M15.0391 1L20.7931 7L15.0391 13" stroke="currentColor" />
      <path d="M21 6.99988H0" stroke="currentColor" />
    </Fragment>
  ),
  viewBox: "0 0 22 14",
  fill: "none",
}