/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const eloIcon  = {
  path: (
    <Fragment>
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" fill="white" />
      <path
        d="M13.0403 15.8262C13.5198 15.669 14.0327 15.5837 14.5664 15.5837C16.8957 15.5837 18.8386 17.2068 19.2844 19.3629L22.5851 18.7027C21.8277 15.0382 18.5258 12.2797 14.5664 12.2797C13.6599 12.2797 12.7874 12.4245 11.9728 12.6914L13.0403 15.8262Z"
        fill="#FECA2F"
      />
      <path
        d="M9.11213 26.4462L11.0885 23.8944C10.2063 23.0021 9.64949 21.6994 9.64949 20.2473C9.64949 18.7964 10.2058 17.4937 11.0877 16.602L9.11032 14.0505C7.61132 15.5665 6.66669 17.7809 6.66669 20.2473C6.66669 22.715 7.6127 24.9302 9.11213 26.4462Z"
        fill="#1BA7DE"
      />
      <path
        d="M19.2854 21.1337C18.8381 23.2895 16.8964 24.9106 14.5692 24.9106C14.0353 24.9106 13.5213 24.825 13.0418 24.6673L11.9728 27.8038C12.7886 28.0719 13.6615 28.217 14.5692 28.217C18.525 28.217 21.8247 25.4604 22.5851 21.7972L19.2854 21.1337Z"
        fill="#EC412A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4658 14.0505V23.1193L40.0933 23.7716L39.3234 25.5603L37.714 24.9134C37.3521 24.7616 37.1072 24.5304 36.9212 24.2693C36.7425 24.0022 36.61 23.637 36.61 23.1442V14.0505H38.4658ZM26.1231 20.7907C26.1635 18.1973 28.3721 16.1271 31.052 16.1669C33.3266 16.2014 35.2113 17.742 35.7063 19.791L26.9089 23.427C26.3979 22.6715 26.1074 21.7627 26.1231 20.7907ZM28.136 21.1543C28.1239 21.0455 28.1154 20.9339 28.1186 20.8213C28.1439 19.2934 29.4441 18.0742 31.0231 18.0995C31.8825 18.1106 32.6456 18.4912 33.1615 19.0802L28.136 21.1543ZM32.9763 22.838C32.4461 23.3367 31.7271 23.6406 30.9332 23.6296C30.389 23.6208 29.8848 23.4633 29.4562 23.2008L28.3934 24.8383C29.1212 25.2832 29.9794 25.5464 30.9046 25.5603C32.2514 25.5796 33.4789 25.068 34.3714 24.2233L32.9763 22.838ZM45.2391 18.0994C44.9221 18.0994 44.6174 18.1489 44.3327 18.2413L43.6995 16.4064C44.1828 16.2503 44.7004 16.1656 45.2391 16.1656C47.5896 16.1656 49.5504 17.7801 50 19.9249L48.0398 20.3113C47.7756 19.0491 46.6221 18.0994 45.2391 18.0994ZM42.0206 24.3817L43.3451 22.9333C42.7535 22.4268 42.381 21.6872 42.381 20.8627C42.381 20.0391 42.7535 19.2998 43.3445 18.7938L42.019 17.3454C41.0141 18.206 40.381 19.4634 40.381 20.8627C40.381 22.2638 41.0146 23.5208 42.0206 24.3817ZM45.2389 23.6271C46.6207 23.6271 47.7741 22.6783 48.0397 21.4179L49.9994 21.8059C49.5476 23.9484 47.5873 25.5608 45.2389 25.5608C44.6999 25.5608 44.1816 25.4757 43.697 25.3189L44.3317 23.4846C44.6169 23.5767 44.9217 23.6271 45.2389 23.6271Z"
        fill="black"
      />
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" stroke="#F2F4F7" />
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 58 40",
}