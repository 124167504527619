/** @jsx jsx */
import { jsx } from "theme-ui"

export const triangleDownIcon  = {
  path: (
    <path
      d="M5.16218 5.77528C5.08235 5.88589 4.91765 5.88589 4.83782 5.77528L0.898676 0.317042C0.803216 0.184769 0.897732 7.95745e-07 1.06085 7.81485e-07L8.93914 9.27429e-08C9.10227 7.84823e-08 9.19678 0.184768 9.10132 0.317041L5.16218 5.77528Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 10 6",
  fill: "none",
}