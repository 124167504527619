/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

import NavLink from "./nav-link"

const HeaderNavLink = (props) => (
  <NavLink
    sx={{
      justifyContent: "start",
      variant: "text.caps",
      fontSize: 0,
      ml: 5,
      "&:last-child": {
        mr: 5,
      },
    }}
    {...props}
  />
)

export default HeaderNavLink
