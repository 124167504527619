/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const ReclameAquiBadge = () => {
  const reclameAquiUrl =
    "https://www.reclameaqui.com.br/empresa/formel-skin/?utm_source=referral&amp;utm_medium=embbed&amp;utm_campaign=ra_verificada&amp;utm_term=horizontal"

  return (
    <div
      id="ra-verified-seal"
      sx={{ backgroundColor: "#fff", width: 136, height: 48 }}
    >
      <div
        sx={{
          borderRadius: 4,
          border: "1px solid #a4c929",
        }}
      >
        <a
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            width: 136,
            height: 48,
          }}
          target="_blank"
          rel="noopener noreferrer"
          title="Selo RA Verificada"
          href={reclameAquiUrl}
        >
          <img
            sx={{
              width: 32,
              height: 32,
            }}
            src="https://s3.amazonaws.com/raichu-beta/ra-verified/assets/images/verified.svg"
            alt="Selo RA Verificada"
            title="Selo RA Verificada"
            loading="lazy"
          />
          <div sx={{ marginLeft: 2, marginRight: 2 }}>
            <div
              sx={{
                marginTop: 2,
                fontFamily: "Open Sans, Arial, Sans-Serif",
                lineHeight: 0.6,
                color: "#4b5963",
                fontSize: 0,
              }}
            >
              Verificada por
            </div>
            <img
              sx={{
                width: 74,
                height: 13,
              }}
              src="https://s3.amazonaws.com/raichu-beta/ra-verified/assets/images/ra-logo.svg"
              alt="Selo RA Verificada"
              title="Selo RA Verificada"
              loading="lazy"
            />
          </div>
        </a>
      </div>
    </div>
  )
}

export default ReclameAquiBadge
