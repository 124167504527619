/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const Tappable = ({ onClick, ...props }) => (
  <button
    onClick={onClick}
    sx={{
      display: "inline-flex",
      appearance: "none",
      mx: 0,
      p: 0,
      userSelect: "none",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      textDecoration: "none",
      lineHeight: "1.2",
      fontSize: "inherit",
      fontFamily: "inherit",
      color: "text",
      backgroundColor: "transparent",
      border: 0,
      cursor: !props.disabled && onClick && "pointer",
      opacity: props.disabled && "0.3",
    }}
    {...props}
  />
)

export default Tappable
