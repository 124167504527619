/** @jsx jsx */
import { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { jsx, useBreakpointIndex } from "@trueskin-web/theme"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"
import { emailIcon } from "@trueskin-web/theme/icons/emailIcon"
import { whatsappIcon } from "@trueskin-web/theme/icons/whatsappIcon"
import { zendeskIcon } from "@trueskin-web/theme/icons/zendeskIcon"
import { Trans } from "@trueskin-web/translations"

import Button, { variants } from "./button"
import Icon from "./icon"
import IconButton from "./icon-button"

const isBrazil = process.env.GATSBY_I18N_LOCALE === "pt-BR"
const whatsAppUrl =
  isBrazil && process.env.GATSBY_WHATSAPP_NUMBER
    ? `https://cdn.smooch.io/message-us/index.html?channel=whatsapp&size=compact&label=WhatsApp&number=${process.env.GATSBY_WHATSAPP_NUMBER}`
    : null

const ContactSupport = ({ placeholderSpace, mobileNavReposition }) => {
  const isMobile = useBreakpointIndex() === 0

  const [isVisible, setIsVisible] = useState(null)
  const [mobileOffset, setMobileOffset] = useState()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const loadZendesk = () => {
    if (window.zE) {
      window.zE("webWidget", "open")
    } else if (window.loadZendesk) {
      window.loadZendesk()
    } else {
      toast(() => <Trans i18nKey="ContactSupport.supportUnavailable" />)
    }
    setIsVisible(null)
    setIsMenuOpen(false)
  }

  useEffect(() => {
    if (window.loadZendesk) {
      if (
        Math.ceil(window.scrollY) >=
        Math.min(
          window.innerHeight / 2,
          document.body.clientHeight - window.innerHeight
        )
      ) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      setMobileOffset(
        window.zESettings?.webWidget?.offset?.mobile?.vertical || "10px"
      )

      if (
        Math.ceil(window.scrollY) >=
        Math.min(
          window.innerHeight / 2,
          document.body.clientHeight - window.innerHeight
        )
      ) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [isVisible])

  useEffect(() => {
    if (!isMobile || !mobileNavReposition) {
      return
    }

    const initZenWidget = (offset) => {
      const zenWidget = window.$zopim?.livechat?.button

      if (zenWidget) {
        zenWidget.setOffsetVerticalMobile(offset)
      } else {
        window.zESettings = {
          webWidget: {
            offset: {
              mobile: {
                vertical: `${offset}px`,
              },
            },
          },
        }
      }
    }

    initZenWidget(70)

    return () => initZenWidget(10)
  }, [isMobile])

  return (
    <div
      sx={{
        height: placeholderSpace ? (isMenuOpen ? 120 : [82, 70]) : undefined,
      }}
    >
      <div
        sx={{
          position: "fixed",
          zIndex: 9,
          right: 0,
          bottom: [isVisible ? mobileOffset : "-70px", isVisible ? 0 : "-70px"],
          m: "10px 0 12px",
          opacity: isVisible ? 1 : 0,
          transitionDuration: "0.25s",
          transitionProperty: "opacity, top, bottom, display",
          transitionTimingFunctioncubicBezier: "(0.645, 0.045, 0.355, 1)",
        }}
      >
        {!isVisible ? null : isMenuOpen ? (
          <div
            sx={{
              position: "relative",
              m: 0,
              pr: 6,
              pl: 0,
              background: "white",
              background: "lightGray",
              boxShadow: "0px 6px 12px rgba(29, 22, 15, 0.17)",
            }}
          >
            <IconButton
              icon={closeIcon}
              iconSize="sm"
              onClick={() => setIsMenuOpen(false)}
              sx={{
                position: "absolute",
                px: 1,
                py: 1,
                zIndex: 10,
              }}
            />

            {whatsAppUrl && (
              <div sx={{ position: "relative", zIndex: 9 }}>
                <iframe
                  width="200"
                  height="46"
                  sx={{
                    position: "absolute",
                    top: 0,
                    opacity: 0,
                    "&:hover + div": {
                      ...variants.link["&:hover"],
                    },
                  }}
                  src={whatsAppUrl}
                />
                <div
                  sx={{
                    ...variants.link,
                    display: "flex",
                    gap: 3,
                    alignItems: "center",
                    justifyContent: "center",
                    py: 3,
                    gap: 3,
                    fontSize: 1,
                    color: "primary",
                    borderBottom: "1px solid",
                    borderBottomColor: "grey",
                    textDecoration: "none",
                  }}
                >
                  <Icon icon={whatsappIcon} sx={{ width: 20, height: 20 }} />
                  <Trans i18nKey="ContactSupport.whatsAppButton" />
                </div>
              </div>
            )}

            <Button
              variant="link"
              onClick={loadZendesk}
              sx={{
                pl: 6,
                gap: 3,
                color: "primary",
                fontSize: 1,
                textDecoration: "none",
              }}
            >
              <Icon icon={emailIcon} size="sm" />
              <Trans i18nKey="ContactSupport.zendeskButton" />
            </Button>
          </div>
        ) : (
          <Button
            onClick={
              process.env.GATSBY_I18N_LOCALE === "pt-BR"
                ? () => setIsMenuOpen(true)
                : loadZendesk
            }
            sx={{
              mx: "20px",
              border: "none",
              p: ["14px", "13px 22px"],
              borderRadius: "999rem",
              letterSpacing: 0.6,
              fontFamily:
                "system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,Arial,sans-serif",
              fontSize: 15,
              fontWeight: "bold",
            }}
          >
            <span
              sx={{
                display: "inline-flex",
              }}
            >
              <Icon
                icon={zendeskIcon}
                sx={{
                  width: 20,
                  height: 20,
                  mr: [undefined, "8px"],
                }}
              />
            </span>
            <span
              sx={{
                display: ["none", "inline-flex"],
                lineHeight: "normal",
              }}
            >
              <Trans i18nKey="ContactSupport.supportButton" />
            </span>
          </Button>
        )}
      </div>
    </div>
  )
}

export default ContactSupport
