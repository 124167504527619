/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import { amexIcon } from "@trueskin-web/theme/icons/amexIcon"
import { eloIcon } from "@trueskin-web/theme/icons/eloIcon"
import { hyperCardIcon } from "@trueskin-web/theme/icons/hyperCardIcon"
import { mastercardIcon } from "@trueskin-web/theme/icons/mastercardIcon"
import { visaIcon } from "@trueskin-web/theme/icons/visaIcon"

import BrandLogo from "../brand-logo"

const PaymentLogosBr = ({ children, className, size }) => (
  <div className={className}>
    <BrandLogo icon={visaIcon} size={size} />
    <BrandLogo icon={mastercardIcon} size={size} />
    <BrandLogo icon={amexIcon} size={size} />
    <BrandLogo icon={eloIcon} size={size} />
    <BrandLogo icon={hyperCardIcon} size={size} />
    {children}
  </div>
)

export default PaymentLogosBr
