/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const sizes = {
  md: {
    width: 222,
    height: 22,
  },
  sm: {
    width: 171,
    height: 16,
  },
  xsm: {
    width: 141,
    height: 30,
  },
}

const Logo = ({ className, color = "primary", size = "md" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1 0 730 80.87"
    sx={{
      display: "block",
      mt: ["2px", 0],
      mb: ["2px", "4px"],
      fill: color,
      ...sizes[size],
    }}
    className={className}
  >
    <path
      d="M0,3.26h45.7v8.47H8.9v27.14h33.76v8.03H8.9v32.35H0V3.26z M89.56,1.63c-19.21,0-32.02,15.85-32.02,39.62
	c0,23.77,12.81,39.62,32.02,39.62s32.02-15.85,32.02-39.62C121.58,17.48,108.78,1.63,89.56,1.63z M89.56,72.4
	c-14.22,0-23.01-11.94-23.01-31.15S75.34,10.1,89.56,10.1c14.22,0,23.01,11.94,23.01,31.15S103.78,72.4,89.56,72.4z M169.77,47.76
	c11.94-1.95,19-9.99,19-22.03c0-14-9.44-22.47-25.29-22.47h-27.68v75.98h8.9V48.41h15.2l16.07,30.83h10.53l-16.72-31.26V47.76z
	 M144.7,39.95V11.72h18.02c11.29,0,17.04,4.78,17.04,14c0,9.33-5.86,14.22-17.04,14.22H144.7z M204.73,3.26h11.94l24.64,60.35h0.22
	l24.64-60.35h11.94v75.98h-9.01V17.59h-0.22l-25.62,61.66h-3.8l-25.62-61.66h-0.22v61.66h-8.9V3.26z M296.66,3.26h45.59v8.47h-36.69
	v24.31h33.65v8.03h-33.65v26.7h36.69v8.47h-45.59V3.26z M357.55,3.26h8.9v67.52h34.3v8.47h-43.2V3.26z M460.97,54.06l9.12-1.63
	c1.85,14.11,8.68,19.97,22.9,19.97c11.07,0,17.69-5.43,17.69-12.26c0-7.6-5.32-11.83-21.38-16.83
	c-18.34-5.75-24.97-11.29-24.97-21.38c0-11.51,9.77-20.3,25.29-20.3c14.55,0,24.97,9.01,27.79,22.14l-8.68,1.95
	c-2.82-10.64-9.33-15.63-19.21-15.63c-10.1,0-16.28,5.32-16.28,11.61c0,5.75,4.67,8.9,19.1,13.57c19.86,6.3,27.25,12.92,27.25,24.64
	c0,12.48-10.75,20.95-26.7,20.95C473.45,80.87,463.58,71.53,460.97,54.06z M535.43,0h8.47v49.06h0.22l31.48-27.35h13.24
	l-27.57,23.55l28.77,33.98h-11.61l-23.66-28.55h-0.22l-10.64,9.33v19.21h-8.47V0z M658.51,71.32v7.92h-54.27v-7.92h22.9V29.63
	h-21.82v-7.92h30.29v49.61H658.51z M636.48,12.27h-10.2V2.17h10.2V12.27z M676.42,21.71h8.47v6.4h0.22c4.45-5.1,11.51-8.03,19-8.03
	c14.87,0,23.88,9.44,23.88,24.64v34.52h-8.47V47c0-12.16-5.75-19-16.06-19c-11.18,0-18.56,7.16-18.56,18.24v33h-8.47V21.71z
	 M420.5,1.63c-12.29,0-22.25,9.96-22.25,22.25c0,12.29,9.96,22.25,22.25,22.25c12.29,0,22.25-9.96,22.25-22.25
	C442.75,11.59,432.79,1.63,420.5,1.63z M433.85,28.33h-8.9v8.9h-8.9v-8.9h-8.9v-8.9h8.9v-8.9h8.9v8.9h8.9V28.33z"
    />
  </svg>
)

export default Logo
