/** @jsx jsx */
import { Global, css } from "@emotion/react"
import { Fragment, useState } from "react"

import { useAuth } from "@trueskin-web/context"
import { getHomepageUrl } from "@trueskin-web/functions"
import { getCountries, getCountry, saveCountry } from "@trueskin-web/locales"
import { jsx } from "@trueskin-web/theme"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"
import { triangleDownIcon } from "@trueskin-web/theme/icons/triangleDownIcon"
import { Routes, Trans } from "@trueskin-web/translations"

import AccountLink from "./account-link"
import Button from "./button"
import Dialog from "./dialog"
import Icon from "./icon"
import IconButton from "./icon-button"
import Link from "./link"
import Logo from "./logo"
import NavLink from "./nav-link"

const countries = getCountries()

const MobileNavLink = (props) => (
  <NavLink
    isFullWidth
    sx={{
      justifyContent: "start",
      lineHeight: 1.5,
      py: 5,
      variant: "text.caps",
      fontFamily: "monospace",
    }}
    {...props}
  />
)

const HeaderMobileLogo = () => {
  const { user } = useAuth()

  const isUserLogged = !!user

  return (
    <Button
      variant="link"
      size="sm"
      as={Link}
      href={getHomepageUrl(isUserLogged)}
      aria-current="page"
      aria-label="Return Home"
      sx={{
        p: 0,
      }}
    >
      <Logo size="xsm" />
    </Button>
  )
}

const HeaderMobileNav = ({ links }) => (
  <nav
    sx={{
      pb: 3,
      borderBottom: "1px",
    }}
  >
    {links.map((link) => (
      <MobileNavLink
        key={link.label}
        doNotTrack={link.label === "FAQ"}
        href={link.targetUrl}
        target={link.label === "FAQ" ? "_blank" : undefined}
      >
        {link.label}
      </MobileNavLink>
    ))}
  </nav>
)

const HeaderMobileActions = ({ cta }) => {
  const { userIdentity } = useAuth()

  const [country, setCountry] = useState(getCountry(false))
  const [countrySelectorVisible, setCountrySelectorVisible] = useState(false)

  return (
    <Fragment>
      {!userIdentity ? (
        <Fragment>
          {countries.length > 0 && (
            <div
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                fontFamily: "monospace",
              }}
            >
              <Button
                onClick={() =>
                  countries.length > 1 ? setCountrySelectorVisible(true) : null
                }
                variant="link"
                sx={{
                  my: 2,
                  pr: 0,
                  fontSize: 1,
                  variant: "text.caps",
                  textDecoration: "none",
                  cursor: countries.length > 1 ? undefined : "default",
                  "&:hover, &:focus, &.focus": {
                    color: countries.length > 1 ? undefined : "text",
                  },
                }}
              >
                <span
                  className={`fi fis fi-${country.code.toLowerCase()}`}
                  sx={{
                    mr: 2,
                  }}
                />
                {`${country.code}`}
                {countries.length > 1 && (
                  <Icon
                    icon={triangleDownIcon}
                    size="xsm"
                    sx={{
                      ml: 1,
                      transform: countrySelectorVisible ? "rotate(180deg)" : "",
                    }}
                  />
                )}
              </Button>
            </div>
          )}

          <div sx={{ display: "grid", gap: 4, mt: "auto" }}>
            {cta ? (
              <Button
                size="lg"
                isFullWidth
                onClick={() => {
                  Object.assign(document.createElement("a"), {
                    href: cta.targetUrl,
                  }).click()
                }}
              >
                {cta.label}
              </Button>
            ) : null}

            <Button
              size="md"
              isFullWidth
              variant="outline"
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  href: Routes.App.Login.url,
                }).click()
              }}
            >
              <Trans i18nKey="Header.login" />
            </Button>
          </div>
        </Fragment>
      ) : (
        <div>
          <div
            sx={{
              fontFamily: "monospace",
              variant: "text.caps",
              mb: 1,
              pt: 4,
              fontSize: 1,
            }}
          >
            Logged in as
          </div>
          <AccountLink
            sx={{
              flexDirection: "row-reverse",
              fontSize: 3,
              fontWeight: "body",
            }}
          />
        </div>
      )}

      {countrySelectorVisible && (
        <CountrySelectionDialog
          onDismiss={() => setCountrySelectorVisible(false)}
          onClick={(country) => {
            saveCountry(country.code)
            setCountry(country)

            setCountrySelectorVisible(false)
            window.location.assign(window.location.origin)
          }}
          countries={countries}
        />
      )}
    </Fragment>
  )
}

const CountrySelectionDialog = ({ onDismiss, onClick, countries }) => (
  <Dialog
    sx={{
      maxWidth: 480,
    }}
    aria-label="country selector"
    onDismiss={onDismiss}
  >
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        sx={{
          fontFamily: "monospace",
          fontSize: 1,
          variant: "text.caps",
          letterSpacing: "0.1em",
          mb: 5,
        }}
      >
        <Trans i18nKey="Header.shippingTo" />
      </div>
      {countries.map((country, index) => (
        <Button
          key={country.code}
          onClick={() => onClick(country)}
          variant="link"
          sx={{
            justifyContent: "flex-start",
            alignItems: "baseline",
            mt: index > 0 ? 2 : null,
            px: 0,
            textDecoration: "none",
          }}
        >
          <span
            className={`fi fis fi-${country.code.toLowerCase()}`}
            sx={{
              mr: 4,
            }}
          />
          <span
            sx={{
              variant: "text.caps",
              fontSize: 1,
            }}
          >
            {country.label}
          </span>
        </Button>
      ))}
    </div>
  </Dialog>
)

const HeaderMobile = ({ links, cta, onClose }) => (
  <Fragment>
    <Global
      styles={css`
        body {
          // mobile ios hack 🙄
          position: fixed;
          width: 100%;
          overflow-y: hidden;
        }
      `}
    />
    <div
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        px: 4,
        bg: "background",
        overflowY: "auto",
        zIndex: 8,
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          py: 3,
        }}
      >
        <HeaderMobileLogo />

        <IconButton
          icon={closeIcon}
          iconSize="lg"
          title="Schließen"
          onClick={onClose}
        />
      </div>
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          pb: 5,
        }}
      >
        <HeaderMobileNav links={links} />

        <HeaderMobileActions cta={cta} />
      </div>
    </div>
  </Fragment>
)

export default HeaderMobile
