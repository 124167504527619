/** @jsx jsx */
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { useEffect } from "react"
import { toast } from "react-toastify"

import {
  marketingClient,
  orderClient,
  useMutation,
  useQueryClient,
} from "@trueskin-web/apis"
import { useAuth } from "@trueskin-web/context"
import { getVoucherError } from "@trueskin-web/functions"
import { voucherGqlClient, useGqlLazyQuery } from "@trueskin-web/gql"
import { storageService, voucherService } from "@trueskin-web/services"
import { jsx } from "@trueskin-web/theme"

import Button from "./button"

const storageKeys = storageService.getStorageKeys()
const storageTypes = storageService.getStorageTypes()
const voucherParam = storageKeys.VOUCHER_URL

const VoucherAutomation = () => {
  const queryClient = useQueryClient()

  const { user, status: userStatus } = useAuth()

  const { mutateAsync: addVoucher } = useMutation(
    orderClient.addCurrentVoucher,
    {
      onSuccess: ({
        data: {
          confirmationMessage,
          voucher: { code },
        },
      }) =>
        (async (message, voucherCode) => {
          await Promise.all([
            queryClient.invalidateQueries("mainCurrentPresets"),
            queryClient.invalidateQueries("currentPresets"),
            queryClient.invalidateQueries("currentOrderSummary"),
          ])
          await Promise.all([
            marketingClient.sendEvent({
              name: marketingClient.EVENT_TYPES_ENUM.VOUCHER_ADDED,
              data: { voucherCode },
            }),
            queryClient.refetchQueries("mainCurrentPresets"),
            queryClient.refetchQueries("currentPresets"),
            queryClient.refetchQueries("currentOrderSummary"),
          ])

          voucherService.saveAppliedVoucher({ code: voucherCode })

          toast(message, {
            toastId: "voucher-add-success-toast",
          })
        })(confirmationMessage, code),
      onError: ({ message }, voucher) => {
        voucherService.saveAppliedVoucher({ error: message, code: voucher })

        toast(
          getVoucherError({
            message,
            voucher,
            components: {
              action: Button,
            },
          }),
          {
            toastId: "voucher-add-error-toast",
          }
        )
      },
      onSettled: () => {
        voucherService.removeVoucher({
          storageType: [storageTypes.SESSION, storageTypes.LOCAL],
        })
      },
    }
  )

  const [checkVoucher] = useGqlLazyQuery(voucherGqlClient.getVoucher, {
    variables: {
      voucher: voucherService.getVoucher({
        storageType: storageTypes.SESSION,
      }),
    },
    onCompleted: ({ getVoucher: { confirmationMessage } }) => {
      const voucher = voucherService.getVoucher({
        storageType: storageTypes.SESSION,
      })

      voucherService.saveVoucher({ data: voucher })

      voucherService.removeVoucher({
        storageType: storageTypes.SESSION,
      })

      toast(confirmationMessage, {
        toastId: "voucher-add-success-toast",
      })
    },
    onError: (message) => {
      const voucher = voucherService.getVoucher({
        storageType: storageTypes.SESSION,
      })

      voucherService.removeVoucher({
        storageType: storageTypes.SESSION,
      })

      toast(
        getVoucherError({
          message,
          voucher,
          components: {
            action: Button,
          },
        }),
        {
          toastId: "voucher-add-error-toast",
        }
      )
    },
  })

  useEffect(() => {
    if (userStatus !== "success") {
      return
    }

    if (!user) {
      const voucher = voucherService.getVoucher({
        storageType: storageTypes.SESSION,
      })

      if (voucher) {
        checkVoucher({ variables: { voucher } })
      }

      return
    }

    const voucher = voucherService.getVoucher({
      storageType: [storageTypes.SESSION, storageTypes.LOCAL],
    })

    if (voucher) {
      addVoucher(voucher)
    }
  }, [userStatus, addVoucher, checkVoucher])

  return null
}

const VoucherUrl = () => {
  const { search, pathname } = useLocation()

  const searchParams = new URLSearchParams(search)
  const urlVoucher = searchParams.get(voucherParam)
  searchParams.delete(voucherParam)
  const queryParams = searchParams.toString()
  const queryString = queryParams ? `?${queryParams}` : queryParams

  if (urlVoucher) {
    voucherService.saveVoucher({
      data: urlVoucher,
      storageType: storageTypes.SESSION,
    })
  }

  useEffect(() => {
    if (urlVoucher) {
      navigate(`${pathname}${queryString}`, {
        replace: true,
      })
    }
  }, [urlVoucher, pathname, queryString])

  if (urlVoucher) {
    return null
  }

  return <VoucherAutomation />
}

export default VoucherUrl
