/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

import { useFormControl } from "./form-control"

const FormErrorMessage = ({
  children,
  mobilePlaceholder,
  desktopPlaceholder,
  ...props
}) => {
  const formControl = useFormControl(props)

  const showMessage = formControl.isInvalid && children

  return (
    <div
      sx={{
        display: showMessage
          ? "flex"
          : [
              mobilePlaceholder ? "flex" : "none",
              desktopPlaceholder ? "flex" : "none",
            ],
        whiteSpace: "break-spaces",
        flexDirection: "column",
        justifyContent: "flex-end",
        mt: 1,
        fontSize: 0,
        color: "attention",
      }}
      {...props}
    >
      <div>{showMessage ? children : " "}</div>
    </div>
  )
}

export default FormErrorMessage
