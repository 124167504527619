/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

import PaymentLogosBr from "./payment-logos-br"
import PaymentLogosDe from "./payment-logos-de"

const spaces = {
  xlg: 2,
  lg: 2,
  md: 1,
}

const Component =
  process.env.GATSBY_I18N_LOCALE === "pt-BR" ? PaymentLogosBr : PaymentLogosDe

const PaymentLogos = ({ size, ...props }) => (
  <Component
    sx={{
      display: "flex",
      flexFlow: "wrap",
      gap: spaces[size],
    }}
    size={size}
    {...props}
  />
)

export default PaymentLogos
