/** @jsx jsx */
import Tippy from "@tippyjs/react"
import { Fragment, useEffect, useState } from "react"

import { useAuth } from "@trueskin-web/context"
import { getHomepageUrl } from "@trueskin-web/functions"
import { getCountries, getCountry, saveCountry } from "@trueskin-web/locales"
import { authService } from "@trueskin-web/services"
import { jsx, useThemeUI } from "@trueskin-web/theme"
import { menuIcon } from "@trueskin-web/theme/icons/menuIcon"
import { triangleDownIcon } from "@trueskin-web/theme/icons/triangleDownIcon"
import { Routes, Trans } from "@trueskin-web/translations"

import AccountLink from "./account-link"
import Button from "./button"
import CapsBar from "./caps-bar"
import Container from "./container"
import HeaderMobile from "./header-mobile"
import HeaderNavLink from "./header-nav-link"
import Icon from "./icon"
import IconButton from "./icon-button"
import Link from "./link"
import Logo from "./logo"

const countries = getCountries()

const HeaderLogo = () => {
  const { user } = useAuth()

  const isUserLogged = !!user

  return (
    <Button
      variant="link"
      size="sm"
      as={Link}
      href={getHomepageUrl(isUserLogged)}
      aria-current="page"
      aria-label="Return Home"
      sx={{
        flex: "none",
        p: 0,
      }}
    >
      <Logo size="xsm" />
    </Button>
  )
}

const HeaderNav = ({ links }) => (
  <nav
    sx={{
      display: ["none", "flex"],
      mx: 3,
      flexWrap: "wrap",
    }}
  >
    {links.map((link) => (
      <HeaderNavLink
        key={link.label}
        doNotTrack={link.label === "FAQ"}
        href={link.targetUrl}
        target={link.label === "FAQ" ? "_blank" : undefined}
      >
        {link.label}
      </HeaderNavLink>
    ))}
  </nav>
)

const HeaderActions = ({ disableNav, links, cta }) => {
  const { userIdentity } = useAuth()

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [storedFirstName, setStoredFirstName] = useState("")

  useEffect(() => {
    setStoredFirstName(authService.getFirstname())
  }, [])

  return (
    <Fragment>
      <div
        sx={{
          display: "flex",
        }}
      >
        <IconButton
          icon={menuIcon}
          iconSize="lg"
          sx={{
            display: [disableNav ? "none" : undefined, "none"],
          }}
          onClick={() => setIsMobileMenuOpen(true)}
        />
        {userIdentity || storedFirstName ? (
          <AccountLink
            sx={{
              display: [disableNav ? undefined : "none", "inline-flex"],
            }}
          />
        ) : (
          <Fragment>
            {disableNav ? null : <CountrySelectionPopup />}

            {cta ? (
              <Button
                size="sm"
                onClick={() => {
                  Object.assign(document.createElement("a"), {
                    href: cta.targetUrl,
                  }).click()
                }}
                sx={{
                  display: ["none", disableNav ? "none" : "inline-flex"],
                  ml: 4,
                  fontSize: 12,
                  whiteSpace: "nowrap",
                  alignSelf: "center",
                }}
              >
                {cta.label}
              </Button>
            ) : null}

            <Button
              size="sm"
              variant="outline"
              onClick={() => {
                Object.assign(document.createElement("a"), {
                  href: Routes.App.Login.url,
                }).click()
              }}
              sx={{
                display: [disableNav ? undefined : "none", "inline-flex"],
                ml: 4,
                fontSize: 12,
                whiteSpace: "nowrap",
                alignSelf: "center",
              }}
            >
              <Trans i18nKey="Header.login" />
            </Button>
          </Fragment>
        )}
      </div>

      {isMobileMenuOpen && (
        <HeaderMobile
          links={links}
          cta={cta}
          onClose={() => setIsMobileMenuOpen(false)}
        />
      )}
    </Fragment>
  )
}

const CountrySelectionPopup = () => {
  const [country, setCountry] = useState("")
  const [countrySelector, setCountrySelector] = useState(null)

  useEffect(() => {
    setCountry(getCountry(false))
  }, [])

  if (!country || countries.length === 0) {
    return null
  }

  return (
    <Tippy
      theme="light"
      placement="bottom-start"
      trigger={countries.length > 1 ? "click" : "manual"}
      hideOnClick={true}
      interactive={true}
      onShow={(instance) => {
        setCountrySelector(instance)
      }}
      onHide={() => {
        setCountrySelector(false)
      }}
      content={
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            py: 2,
          }}
        >
          <div
            sx={{
              textAlign: "left",
              fontFamily: "monospace",
              fontSize: 0,
              variant: "text.caps",
              letterSpacing: "0.1em",
              px: 3,
              py: 1,
              mb: 2,
            }}
          >
            <Trans i18nKey="Header.shippingTo" />
          </div>
          {countries.map((country) => (
            <Button
              key={country.code}
              onClick={() => {
                saveCountry(country.code)
                setCountry(country)

                countrySelector.hide()
                window.location.assign(window.location.origin)
              }}
              variant="link"
              size="sm"
              sx={{
                justifyContent: "flex-start",
                fontFamily: "monospace",
                fontSize: 0,
                variant: "text.caps",
                textDecoration: "none",
              }}
            >
              <span
                className={`fi fis fi-${country.code.toLowerCase()}`}
                sx={{
                  mr: 2,
                }}
              />
              {country.label}
            </Button>
          ))}
        </div>
      }
    >
      <Button
        variant="link"
        sx={{
          display: ["none", "inline-flex"],
          alignSelf: "center",
          p: 0,
          variant: "text.caps",
          fontSize: 1,
          textDecoration: "none",
          cursor: countries.length > 1 ? undefined : "default",
          "&:hover, &:focus, &.focus": {
            color: countries.length > 1 ? undefined : "text",
          },
        }}
      >
        <span
          className={`fi fis fi-${country.code.toLowerCase()}`}
          sx={{
            mr: 2,
          }}
        />
        {`${country.code}`}
        {countries.length > 1 && (
          <Icon
            icon={triangleDownIcon}
            size="xsm"
            sx={{
              ml: 1,
              transform: countrySelector ? "rotate(180deg)" : "",
            }}
          />
        )}
      </Button>
    </Tippy>
  )
}

const Header = ({
  primaryCta,
  links,
  title,
  isFloating,
  disableNav,
  disableMobileHeader,
}) => {
  const {
    theme: {
      sizes: { container: containerSize, element: elementSize },
    },
  } = useThemeUI()

  const [isInited, setIsInited] = useState(false)

  useEffect(() => {
    setIsInited(true)
  }, [])

  return (
    <header
      sx={{
        position: "relative",
        minHeight: [disableMobileHeader || !title ? 60 : 99, 60],
      }}
    >
      <Container
        sx={{
          display: isInited ? undefined : "none",
          position: isFloating ? "absolute" : undefined,
          top: 0,
          left: 0,
          right: 0,
          zIndex: 8,
          maxWidth: "100%",
          minWidth: `min(100%, ${containerSize}px)`,
          textAlign: [null, "center"],
        }}
      >
        <div
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 3,
            maxWidth: "100%",
            minWidth: ["100%", `min(100%, ${elementSize}px)`],
          }}
        >
          <HeaderLogo />

          {disableNav ? null : <HeaderNav links={links} />}

          <HeaderActions
            disableNav={disableNav}
            links={links}
            cta={primaryCta}
          />
        </div>

        {disableMobileHeader || !title ? null : (
          <CapsBar
            as="h3"
            sx={{
              display: [undefined, "none"],
              my: 0,
              fontWeight: "normal",
              textAlign: "left",
            }}
          >
            {title}
          </CapsBar>
        )}
      </Container>
    </header>
  )
}

export default Header
