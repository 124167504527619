/** @jsx jsx */
import { keyframes } from "@emotion/react"
import ConditionalWrap from "conditional-wrap"
import { useEffect } from "react"

import { jsx } from "@trueskin-web/theme"

const spin = keyframes({
  from: {
    transform: "rotate(0deg)",
  },
  to: {
    transform: "rotate(360deg)",
  },
})

const Spinner = ({
  size = 48,
  strokeWidth = 4,
  isFullPage,
  disableScroll,
  className,
}) => {
  const r = 16 - strokeWidth
  const C = 2 * r * Math.PI
  const offset = C - (1 / 4) * C

  useEffect(() => {
    if (isFullPage && disableScroll) {
      document.querySelector("body").style.overflow = "hidden"

      return () => {
        document.querySelector("body").style.overflow = ""
      }
    }
  }, [disableScroll])

  return (
    <ConditionalWrap
      condition={isFullPage}
      wrap={(children) => (
        <div
          data-testid="web-portal-page-loader"
          sx={{ display: "grid", placeItems: "center", height: "100vh" }}
          className={className}
        >
          {children}
        </div>
      )}
      data-component-name="Spinner"
    >
      <svg
        viewBox="0 0 32 32"
        width={size}
        height={size}
        strokeWidth={strokeWidth}
        fill="none"
        stroke="currentcolor"
        role="img"
        sx={{
          color: "primary",
          overflow: "visible",
        }}
        className={isFullPage ? null : className}
      >
        <title>Loading...</title>
        <circle cx={16} cy={16} r={r} opacity={1 / 8} />
        <circle
          cx={16}
          cy={16}
          r={r}
          strokeDasharray={C}
          strokeDashoffset={offset}
          sx={{
            transformOrigin: "50% 50%",
            animationName: spin.toString(),
            animationTimingFunction: "linear",
            animationDuration: "500ms",
            animationIterationCount: "infinite",
          }}
        />
      </svg>
    </ConditionalWrap>
  )
}

export default Spinner
