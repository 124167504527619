/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import { Footer as FooterContainer } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const Footer = () => {
  const {
    strapi: {
      footer: {
        heading,
        instagramUrl,
        facebookUrl,
        primaryLinks,
        secondaryLinks,
      },
    },
    trustedShops: {
      response: {
        data: {
          shop: {
            qualityIndicators: {
              reviewIndicator: { trustScore, trustScoreDescription },
            },
          },
        },
      },
    },
    trustmarkImage,
  } = useStaticQuery(graphql`
    {
      strapi {
        footer {
          heading
          primaryLinks {
            label
            targetUrl
          }
          secondaryLinks {
            label
            targetUrl
          }
          facebookUrl
          instagramUrl
        }
      }
      trustedShops {
        response {
          data {
            shop {
              qualityIndicators {
                reviewIndicator {
                  trustScore: overallMark
                  trustScoreDescription: overallMarkDescriptionGUILang
                }
              }
            }
          }
        }
      }
      trustmarkImage: file(relativePath: { eq: "trustedshops-trustmark.png" }) {
        childImageSharp {
          gatsbyImageData(width: 32, height: 32, layout: FIXED)
        }
      }
    }
  `)

  const trustShopsLogo = (
    <GatsbyImage
      image={trustmarkImage.childImageSharp.gatsbyImageData}
      alt="trusted shop"
    />
  )

  const healthEcLogo = (
    <StaticImage
      src="../images/health-EC-DE.png"
      alt="Health EC"
      width={120}
      sx={{
        ml: 4,
      }}
    />
  )

  const legitScriptLogo = (
    <StaticImage
      src="https://static.legitscript.com/seals/12533338.png"
      quality={100}
      alt="LegitScript approved"
      width={120}
    />
  )

  return (
    <FooterContainer
      heading={heading}
      instagramUrl={instagramUrl}
      facebookUrl={facebookUrl}
      primaryLinks={primaryLinks}
      secondaryLinks={secondaryLinks}
      trustShopsLogo={trustShopsLogo}
      trustScore={trustScore}
      trustScoreDescription={trustScoreDescription}
      healthEcLogo={healthEcLogo}
      legitScriptLogo={legitScriptLogo}
    />
  )
}

export default Footer
