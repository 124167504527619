/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

import FieldMessage from "./field-message"
import { useFormControl } from "./form-control"

const FormMessage = ({
  children,
  mobilePlaceholder,
  desktopPlaceholder,
  ...props
}) => {
  const formControl = useFormControl(props)

  const showMessage = !formControl.isInvalid && children

  return (
    <FieldMessage
      sx={{
        display: showMessage
          ? "flex"
          : [
              mobilePlaceholder ? "flex" : "none",
              desktopPlaceholder ? "flex" : "none",
            ],
      }}
      {...props}
    >
      <div>{showMessage ? children : " "}</div>
    </FieldMessage>
  )
}

export default FormMessage
