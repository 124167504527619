/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

export const klarnaIcon  = {
  path: (
    <Fragment>
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" fill="#FEB4C7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78199 25.6327H6.4762V15H8.78199V25.6327ZM14.5386 15H12.2828C12.2828 16.9567 11.4328 18.7526 9.95067 19.9276L9.05697 20.6359L12.5198 25.6331H15.3669L12.1806 21.0349C13.691 19.4433 14.5386 17.3019 14.5386 15ZM18.2163 25.6298H16.0388V15.002H18.2163V25.6298ZM24.7954 18.2781V18.7484C24.2079 18.3242 23.4987 18.0749 22.7338 18.0749C20.7094 18.0749 19.0683 19.8117 19.0683 21.9541C19.0683 24.0966 20.7094 25.8333 22.7338 25.8333C23.4987 25.8333 24.2079 25.584 24.7954 25.1599V25.6298H26.8753V18.2781H24.7954ZM24.7891 21.9542C24.7891 22.9988 23.9454 23.8457 22.9045 23.8457C21.8638 23.8457 21.02 22.9988 21.02 21.9542C21.02 20.9095 21.8638 20.0628 22.9045 20.0628C23.9454 20.0628 24.7891 20.9095 24.7891 21.9542ZM46.7372 18.7484V18.2781H48.817V25.6298H46.7372V25.1599C46.1497 25.584 45.4405 25.8333 44.6755 25.8333C42.6511 25.8333 41.01 24.0966 41.01 21.9541C41.01 19.8117 42.6511 18.0749 44.6755 18.0749C45.4405 18.0749 46.1497 18.3242 46.7372 18.7484ZM44.8462 23.8457C45.8871 23.8457 46.7308 22.9988 46.7308 21.9542C46.7308 20.9095 45.8871 20.0628 44.8462 20.0628C43.8054 20.0628 42.9617 20.9095 42.9617 21.9542C42.9617 22.9988 43.8054 23.8457 44.8462 23.8457ZM49.7196 24.4036C49.7196 23.6407 50.3039 23.0223 51.0247 23.0223C51.7455 23.0223 52.3299 23.6407 52.3299 24.4036C52.3299 25.1663 51.7455 25.7848 51.0247 25.7848C50.3039 25.7848 49.7196 25.1663 49.7196 24.4036ZM37.3142 18.0804C36.4834 18.0804 35.6971 18.3534 35.1714 19.1065V18.2786H33.1006V25.6298H35.1968V21.7665C35.1968 20.6485 35.9052 20.1011 36.7581 20.1011C37.6722 20.1011 38.1977 20.679 38.1977 21.7513V25.6298H40.275V20.9547C40.275 19.2439 38.9897 18.0804 37.3142 18.0804ZM30.0927 18.2783V19.2358C30.5096 18.6614 31.2864 18.2787 32.131 18.2787V20.4179C32.1271 20.4179 32.1233 20.4176 32.1195 20.4174C32.1151 20.4172 32.1108 20.4169 32.1063 20.4169C31.2834 20.4169 30.0974 21.0394 30.0974 22.1974V25.6298H27.9633V18.2783H30.0927Z"
        fill="#17120F"
      />
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" stroke="#F2F4F7" />
    </Fragment>
  ),
  fill: "none",
  viewBox: "0 0 58 40",
}